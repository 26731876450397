import { OrderStates } from '../types/order.type';

export function getOrderState(s: OrderStates): string {
    switch (s) {
        case OrderStates.CREATED:
            return 'Oluşturuldu';
        case OrderStates.PAYMENT_FAILED:
            return 'Ödeme başarısız';
        case OrderStates.PAYMENT_COMPLETED:
            return 'Ödeme başarılı';
        case OrderStates.WAITING_APPROVE:
            return 'Onay bekliyor';
        case OrderStates.PREPARING:
            return 'Hazırlanıyor';
        case OrderStates.SHIPPED:
            return 'Kargolandı';
        case OrderStates.COMPLETED:
            return 'Tamamlandı';
        case OrderStates.REJECTED:
            return 'Sipariş Reddedildi';
        case OrderStates.REFUNDED:
            return 'İade edildi';
        default:
            return 'Bilinmiyor!';
    }
}
