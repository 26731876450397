import styles from './Input.module.css';

import { useEffect, useState } from 'react';
import {
    BtnBold,
    BtnItalic,
    Editor,
    EditorProvider,
    Toolbar,
} from 'react-simple-wysiwyg';

interface BaseInputProps {
    isDisabled?: boolean;
    fullWidth?: boolean;
    label: string;
}

interface TextAreaInputProps extends BaseInputProps {
    rows?: number;
    onChange: (val: string) => void;
    value?: string;
    defaultValue?: string;
}

interface HTMLInputProps extends BaseInputProps {
    onChange: (val: string) => void;
    value?: string;
    defaultValue?: string;
}

interface TextInputProps extends BaseInputProps {
    onChange: (val: string) => void;
    value?: string;
    defaultValue?: string;
}

interface PasswordInputProps extends BaseInputProps {
    onChange: (val: string) => void;
    value?: string;
    defaultValue?: string;
}

interface FileInputProps extends BaseInputProps {
    onChange: (file: File | null) => void;
    fileTypes?: Array<string>;
}

interface NumberInputProps extends BaseInputProps {
    onChange: (val: number) => void;
    value?: number;
    defaultValue?: number;
}

interface CurrencyInputProps extends BaseInputProps {
    onChange: (val: number) => void;
    value?: number;
    defaultValue?: number;
}

interface SelectInputProps<T extends string> extends BaseInputProps {
    onChange: (val: T) => void;
    defaultValue?: T;
    value: T;
    items: Array<{
        label: string;
        val: T;
    }>;
}

export function SelectInput<T extends string>(props: SelectInputProps<T>) {
    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <select
                disabled={props.isDisabled}
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value as T);
                }}
            >
                {props.items.map((x) => (
                    <option key={x.val} value={x.val}>
                        {x.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export function TextAreaInput(props: TextAreaInputProps) {
    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <textarea
                value={props.value}
                defaultValue={props.defaultValue}
                disabled={props.isDisabled}
                rows={props.rows}
                onChange={(e) => {
                    props.onChange(e.target.value);
                }}
            />
        </div>
    );
}

export function TextInput(props: TextInputProps) {
    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <input
                defaultValue={props.defaultValue}
                value={props.value}
                disabled={props.isDisabled}
                type="text"
                onChange={(e) => {
                    props.onChange(e.target.value);
                }}
            />
        </div>
    );
}

export function PasswordInput(props: PasswordInputProps) {
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <div className={styles.passwordWrapper}>
                <input
                    value={props.value}
                    defaultValue={props.defaultValue}
                    disabled={props.isDisabled}
                    type={isPasswordVisible ? 'text' : 'password'}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
                <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className={styles.toggleButton}
                >
                    {isPasswordVisible ? (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="eyes"
                                fill="currentColor"
                            >
                                <path d="M2.293,21.707a1,1,0,0,0,1.414,0l3.2-3.2A9.581,9.581,0,0,0,12,20c4.325,0,8.227-3,9.938-7.654a.993.993,0,0,0,0-.692A12.6,12.6,0,0,0,18.7,6.719l3.012-3.012a1,1,0,1,0-1.414-1.414l-3.2,3.2A9.581,9.581,0,0,0,12,4C7.675,4,3.773,7,2.062,11.654a.993.993,0,0,0,0,.692,12.6,12.6,0,0,0,3.243,4.935L2.293,20.293A1,1,0,0,0,2.293,21.707ZM17.266,8.148A10.454,10.454,0,0,1,19.929,12c-1.478,3.657-4.556,6-7.929,6a7.52,7.52,0,0,1-3.632-.954l1.613-1.613A3.947,3.947,0,0,0,12,16a4,4,0,0,0,4-4,3.947,3.947,0,0,0-.567-2.019Zm-7.191,4.363A1.96,1.96,0,0,1,10,12a2,2,0,0,1,2-2,1.96,1.96,0,0,1,.511.075Zm3.85-1.022A1.96,1.96,0,0,1,14,12a2,2,0,0,1-2,2,1.96,1.96,0,0,1-.511-.075ZM4.071,12C5.549,8.343,8.627,6,12,6a7.52,7.52,0,0,1,3.632.954L14.019,8.567A3.947,3.947,0,0,0,12,8a4,4,0,0,0-4,4,3.947,3.947,0,0,0,.567,2.019L6.734,15.852A10.454,10.454,0,0,1,4.071,12Z"></path>
                            </svg>
                        </>
                    ) : (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="eyes"
                                fill="currentColor"
                            >
                                <path d="M2.062,12.346C3.773,17,7.675,20,12,20s8.227-3,9.938-7.654a.993.993,0,0,0,0-.692C20.227,7,16.325,4,12,4S3.773,7,2.062,11.654A.993.993,0,0,0,2.062,12.346ZM12,6c3.373,0,6.451,2.343,7.929,6-1.478,3.657-4.556,6-7.929,6s-6.451-2.343-7.929-6C5.549,8.343,8.627,6,12,6Z"></path>
                                <path d="M12,16a4,4,0,1,0-4-4A4,4,0,0,0,12,16Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,10Z"></path>
                            </svg>
                        </>
                    )}
                </button>
            </div>
        </div>
    );
}

// TODO: add file size controls
export function FileInput(props: FileInputProps) {
    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <input
                disabled={props.isDisabled}
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        if (props.fileTypes && props.fileTypes.length) {
                            if (file && props.fileTypes.includes(file.type)) {
                                props.onChange(file);
                            } else {
                                alert('Desteklenmeyen bir dosya secildi');
                                props.onChange(null);
                            }
                        }
                        props.onChange(file);
                    } else {
                        props.onChange(null);
                    }
                }}
            />
        </div>
    );
}

export function NumberInput(props: NumberInputProps) {
    const [inputValue, setInputValue] = useState<number | null>(
        props.defaultValue ?? null
    );

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setInputValue(props.defaultValue || null);
        }
    }, [props.defaultValue]);
    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <input
                value={inputValue === 0 ? '' : (inputValue ?? '')}
                disabled={props.isDisabled}
                type="number"
                onChange={(e) => {
                    const val = Number(e.target.value);
                    const newValue = Number.isNaN(val) ? 0 : val;
                    setInputValue(newValue); // Update local state
                    props.onChange(newValue); // Call the parent handler
                }}
            />
        </div>
    );
}

export function CurrencyInput(props: CurrencyInputProps) {
    const [inputValue, setInputValue] = useState(props.defaultValue || '');

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setInputValue(formatCurrency(props.defaultValue.toString()));
        }
    }, [props.defaultValue]);

    const formatCurrency = (value: string) => {
        if (!value) return '';
        const [integerPart, decimalPart] = value.split('.');
        const formattedInteger = Number(integerPart).toLocaleString('en-US');
        return decimalPart !== undefined
            ? `${formattedInteger}.${decimalPart}`
            : formattedInteger;
    };

    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>
            <input
                value={inputValue}
                disabled={props.isDisabled}
                type="text"
                onChange={(e) => {
                    const rawValue = e.target.value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except the decimal
                    if (rawValue === '') {
                        setInputValue('');
                        props.onChange(0);
                        return;
                    }

                    const formattedValue = formatCurrency(rawValue);
                    setInputValue(formattedValue);
                    props.onChange(parseFloat(rawValue) || 0);
                }}
            />
        </div>
    );
}

export function HTMLInput(props: HTMLInputProps) {
    return (
        <div
            className={`${styles.inputWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>{props.label}</label>

            <EditorProvider>
                <Editor
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e.target.value || '');
                    }}
                >
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
}

interface CheckboxInputProps extends BaseInputProps {
    onChange: (val: boolean) => void;
    checked?: boolean;
    defaultChecked?: boolean;
}

export function CheckboxInput(props: CheckboxInputProps) {
    return (
        <div
            className={`${styles.inputWrapper} ${styles.checkboxWrapper} ${props.fullWidth ? styles.fullWidth : ''}`}
        >
            <label>
                {props.label}

                <input
                    type="checkbox"
                    checked={props.checked}
                    defaultChecked={props.defaultChecked}
                    disabled={props.isDisabled}
                    onChange={(e) => {
                        props.onChange(e.target.checked);
                    }}
                />
            </label>
        </div>
    );
}
