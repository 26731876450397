import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '../../components/Form/Form';
import { CurrencyInput, TextInput } from '../../components/Input/Input';
import { Table } from '../../components/Table/Table';
import { SiteConfig } from '../../types/siteConfig.type';

export function SiteConfigDetail() {
    const [siteConfig, setSiteConfig] = useState<SiteConfig>({
        shipmentFee: 0,
        discountedShipmentFee: 0,
        abroadShipmentFee: 0,
        discountedAbroadShipmentFee: 0,
        email: '',
        phoneNumber: '',
    });
    const onFieldChange = useCallback(
        function <K extends keyof SiteConfig>(key: K, val: SiteConfig[K]) {
            setSiteConfig({
                ...siteConfig,
                [key]: val,
            });
        },
        [siteConfig]
    );
    const saveSiteConfig = useCallback(async () => {
        try {
            await axios.patch(`/site-config`, siteConfig);
            await fetchSiteConfig();
            alert('Site ayarlari basariyla guncellendi!');
        } catch (error) {
            console.error(
                'Something went wrong during save site config: ',
                error
            );
            alert('Site ayarlari guncellenirken bir sorun olustu!');
        }
    }, [siteConfig]);
    const fetchSiteConfig = useCallback(async () => {
        const res = await axios('/site-config');
        setSiteConfig(res.data);
    }, [axios]);

    useEffect(() => {
        fetchSiteConfig();
    }, []);

    return (
        <div>
            <h2>Site Ayalari</h2>
            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: saveSiteConfig,
                    },
                ]}
                onSubmit={saveSiteConfig}
            >
                <TextInput
                    label="E-Posta:"
                    value={siteConfig.email}
                    onChange={(val) => onFieldChange('email', val)}
                />
                <TextInput
                    label="Telefon Numarasi:"
                    value={siteConfig.phoneNumber}
                    onChange={(val) => onFieldChange('phoneNumber', val)}
                />
                <CurrencyInput
                    label="Kargo Ucreti:"
                    defaultValue={siteConfig.shipmentFee}
                    onChange={(val) => onFieldChange('shipmentFee', val)}
                />
                <CurrencyInput
                    label="Indirimli Kargo Ucreti:"
                    defaultValue={siteConfig.discountedShipmentFee}
                    onChange={(val) =>
                        onFieldChange('discountedShipmentFee', val)
                    }
                />
                <CurrencyInput
                    label="Yurtdisi Kargo Ucreti:"
                    defaultValue={siteConfig.abroadShipmentFee}
                    onChange={(val) => onFieldChange('abroadShipmentFee', val)}
                />
                <CurrencyInput
                    label="Yurtdisi Indirimli Kargo Ucreti:"
                    defaultValue={siteConfig.discountedAbroadShipmentFee}
                    onChange={(val) =>
                        onFieldChange('discountedAbroadShipmentFee', val)
                    }
                />
            </Form>
        </div>
    );
}
