import axios from 'axios';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/Form/Form';
import { TextInput } from '../../components/Input/Input';
import { CreateCategoryRequest } from '../../types/category.type';
import { isSlug } from '../../utils/slug';

import styles from './CreateCategoriy.module.css';

export function CreateCategory() {
    const navigate = useNavigate();
    const [payload, setPayload] = useState<CreateCategoryRequest>({
        name: '',
        slug: '',
    });
    const onFieldChange = useCallback(
        function <K extends keyof CreateCategoryRequest>(
            key: K,
            val: CreateCategoryRequest[K]
        ) {
            setPayload({
                ...payload,
                [key]: val,
            });
        },
        [payload]
    );

    const onSaveCategory = useCallback(async () => {
        if (payload.name.length === 0) {
            alert('Kategori isimi bos birakilamaz!');
            return;
        }
        if (payload.slug.length === 0) {
            alert('Kategori linki bos birakilamaz!');
            return;
        }
        if (!isSlug(payload.slug)) {
            alert('Kategori linki sadece a-z ve - icerebilir!');
            return;
        }

        try {
            const res = await axios.post('/categories', {
                ...payload,
            });

            alert('Kategori basariyla olusturuldu!');
            navigate(`/categories/${res.data.id}/detail`);
        } catch (error) {
            alert('Kategori olusuturulurken bir sorun olustu!');
            return;
        }
    }, [axios, payload]);

    return (
        <div className={styles.container}>
            <h2>Kategori Olustur</h2>

            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: onSaveCategory,
                    },
                ]}
                onSubmit={onSaveCategory}
            >
                <TextInput
                    label="Isim:"
                    defaultValue={payload.name}
                    onChange={(val) => onFieldChange('name', val)}
                />
                <TextInput
                    label="Link:"
                    defaultValue={payload.slug}
                    onChange={(val) => onFieldChange('slug', val)}
                />
            </Form>
        </div>
    );
}
