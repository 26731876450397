import styles from './Layout.module.css';

import { Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/user.context';
import { Navbar } from '../Navbar/Navbar';

export function Layout() {
    const navigate = useNavigate();
    const { user, isLoaded } = useUser();
    if (!isLoaded) {
        return <></>;
    }

    if (!user) {
        navigate('/login');
        return <></>;
    }

    return (
        <div className={styles.container}>
            <Navbar />
            <div className={styles.pageWrapper}>
                <Outlet />
            </div>
        </div>
    );
}
