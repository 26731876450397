import styles from './ProductList.module.css';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
    SortEnd,
} from 'react-sortable-hoc';
import { Table } from '../../components/Table/Table';
import { ProductSummary } from '../../types/product.type';

function renderHeaders() {
    return (
        <thead>
            <tr>
                <th> # </th>
                <th>Ürün adi</th>
                <th>Fiyat</th>
                <th>İşlemler</th>
            </tr>
        </thead>
    );
}

export function ProductList() {
    const [products, setProducts] = useState<Array<ProductSummary>>([]);
    const onRemove = useCallback(async (id: string) => {
        try {
            await axios.delete(`/products/${id}`);
            await fetchProducts();
            alert('Urun basariyla silindi!');
        } catch (error) {
            console.error(
                'Something went wrong during delete product: ',
                error
            );
            alert('Urun silinirken bir sorun olustu!');
        }
    }, []);
    const renderItems = useCallback((item: ProductSummary) => {
        return (
            <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.price}TL</td>
                <td className="table-actions">
                    <Link to={`/products/${item.id}/detail`}>Detay</Link>
                    <button onClick={() => onRemove(item.id)}>Sil</button>
                </td>
            </tr>
        );
    }, []);

    const fetchProducts = useCallback(async () => {
        const res = await axios('/products');
        setProducts(res.data);
    }, [axios]);

    const onSortEnd = useCallback(
        async ({ oldIndex, newIndex }: SortEnd) => {
            const newItems = arrayMove(products, oldIndex, newIndex);
            const newOrder = newItems.map((item) => item.id);
            await axios.patch(`/products/order`, {
                productIds: newOrder,
            });
            await fetchProducts();
            alert('Sira guncellendi!');
        },
        [products, fetchProducts]
    );

    const DragHandle = SortableHandle(() => (
        <span className={styles.dragHandle}>☰</span>
    ));

    const ProductSortableItem = useCallback(
        SortableElement<ProductSummary>((item: ProductSummary) => (
            <tr key={item.id}>
                <td>
                    <DragHandle />
                </td>
                <td>{item.name}</td>
                <td>{item.price}TL</td>
                <td className="table-actions">
                    <Link to={`/products/${item.id}/detail`}>Detay</Link>
                    <button onClick={() => onRemove(item.id)}>Sil</button>
                </td>
            </tr>
        )),
        [DragHandle]
    );
    const ProductSortableList = useCallback(
        SortableContainer<{
            items: Array<ProductSummary>;
        }>(({ items }: { items: Array<ProductSummary> }) => (
            <Table
                renderItems={(item: ProductSummary, index: number) => (
                    <ProductSortableItem
                        key={item.id}
                        index={index}
                        {...item}
                    />
                )}
                renderHeaders={renderHeaders}
                items={items}
            />
        )),
        [DragHandle]
    );

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div>
            <h2>Ürünler</h2>
            <div className={styles.sortableContainer}>
                <ProductSortableList
                    useDragHandle
                    items={products}
                    onSortEnd={onSortEnd}
                    axis="y"
                />
            </div>
        </div>
    );
}
