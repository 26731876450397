import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../components/Table/Table';
import { Category } from '../../types/category.type';
import { ProductSummary } from '../../types/product.type';

function renderHeaders() {
    return (
        <thead>
            <tr>
                <th>Kategori adi</th>
                <th>Kategori linki</th>
                <th>İşlemler</th>
            </tr>
        </thead>
    );
}

export function CategoryList() {
    const [categories, setCategories] = useState<Array<Category>>([]);
    const onRemove = useCallback(async (id: string) => {
        try {
            await axios.delete(`/categories/${id}`);
            await fetchCategories();
            alert('Kategori basariyla silindi!');
        } catch (error) {
            console.error(
                'Something went wrong during delete category: ',
                error
            );
            alert('Kategori silinirken bir sorun olustu!');
        }
    }, []);
    const renderItems = useCallback((item: Category) => {
        return (
            <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.slug}</td>
                <td className="table-actions">
                    <Link to={`/categories/${item.id}/detail`}>Detay</Link>
                    <button onClick={() => onRemove(item.id)}>Sil</button>
                </td>
            </tr>
        );
    }, []);

    const fetchCategories = useCallback(async () => {
        const res = await axios('/categories');
        setCategories(res.data);
    }, [axios]);

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div>
            <h2>Ürünler</h2>
            <Table
                renderItems={renderItems}
                renderHeaders={renderHeaders}
                items={categories}
            />
        </div>
    );
}
