import { PaginatedResponse } from './pagination.type';
import { DiscountTypes } from './product.type';
import { ProductSize } from './productSize.type';

export enum PaymentMethods {
    ONLINE = 'ONLINE',
    ON_DELIVERY = 'ON_DELIVERY',
}

export enum OrderStates {
    CREATED = 'CREATED',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
    WAITING_APPROVE = 'WAITING_APPROVE',
    PREPARING = 'PREPARING',
    SHIPPED = 'SHIPPED',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
    REFUNDED = 'REFUNDED',
}

export interface OrderSummary {
    id: string;
    customerFirstName: string;
    customerLastName: string;
    externalPaymentId: string;
    paymentMethod: PaymentMethods;
    state: OrderStates;
    createdAt: Date;
}

export type PaginatedOrders = PaginatedResponse<IOrderDetail>;

export interface PaginateOrderFilters {
    states?: Array<OrderStates>;
    customerFirstName?: string;
    customerLastName?: string;
    customerEmail?: string;
    customerPhoneNumber?: string;
}

export interface IOrderDetail {
    id: string;
    totalPrice: number;
    discountedTotalPrice: number;
    customerFirstName: string;
    customerLastName: string;
    customerEmail: string;
    customerPhoneNumber: string;
    paymentMethod: PaymentMethods;
    externalPaymentId: string;
    note: string;
    shipmentAddress: string;
    shipmentCountry: string;
    shipmentCity: string;
    shipmentDistrict: string;
    state: OrderStates;
    shipmentTrackingInfo?: string;
    shipmentFee: number;
    currencyCode: string;
    products: OrderProductDetail[];
    createdAt: Date;
    updatedAt: Date;
}

export interface OrderProductDetail {
    id: string;
    productSizeId: string;
    productSize: ProductSize;
    itemPrice: number;
    quantity: number;
    discountAmount?: number;
    discountType?: DiscountTypes;
    freeShipment: boolean;
}
