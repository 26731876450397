import { DiscountTypes } from '../types/product.type';

export function getDiscountType(t?: DiscountTypes): string {
    switch (t) {
        case DiscountTypes.AMOUNT:
            return 'Tutar';
        case DiscountTypes.PERCENT:
            return 'Yuzde';
        default:
            return '-';
    }
}

export function getDiscountAmount(
    amount?: number,
    currencyCode?: string,
    t?: DiscountTypes
): string {
    switch (t) {
        case DiscountTypes.AMOUNT:
            return `${amount} ${currencyCode}`;
        case DiscountTypes.PERCENT:
            return `${amount}%`;
        default:
            return '-';
    }
}
