import styles from './Form.module.css';

import { ReactNode, useCallback } from 'react';

export interface FormProps {
    children: Array<ReactNode> | ReactNode;
    actions?: Array<{
        isDisabled?: boolean;
        text: string;
        onClick: () => void;
        className?: string;
    }>;
    onSubmit?: () => void;
    fullWidth?: boolean;
}

export function Form(props: FormProps) {
    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault(); // Prevents default form submission
            props.onSubmit?.();
        },
        [props.onSubmit]
    );

    return (
        <form
            onSubmit={handleSubmit}
            className={`${styles.container} ${props.fullWidth && styles.fullWidth}`}
        >
            {props.children}
            <div className={styles.actions}>
                {props.actions?.map((action, index) => (
                    <button
                        key={index}
                        disabled={action.isDisabled}
                        onClick={(e) => {
                            e.preventDefault();
                            action.onClick();
                        }}
                        className={action.className}
                    >
                        {action.text}
                    </button>
                ))}
            </div>
        </form>
    );
}
