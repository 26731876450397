import { PaymentMethods } from '../types/order.type';

export function getPaymentMethod(p: PaymentMethods) {
    switch (p) {
        case PaymentMethods.ONLINE:
            return 'Online';
        case PaymentMethods.ON_DELIVERY:
            return 'Kapida Odeme';
        default:
            return 'Bilinmiyor!';
    }
}
