import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Category } from '../../types/category.type';
import { SelectInput } from '../Input/Input';

export interface CategorySelectProps {
    value: string;
    onCategorySelect: (id: string) => void;
}

export function CategorySelect(props: CategorySelectProps) {
    const [categories, setCategories] = useState<Array<Category>>([]);
    const fetchCategories = useCallback(async () => {
        const res = await axios('/categories');
        setCategories(res.data);
    }, []);

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <SelectInput
            value={props.value}
            label="Kategori: "
            items={[
                {
                    label: 'Kategori Seciniz',
                    val: '',
                },
                ...categories.map((item) => ({
                    val: item.id,
                    label: item.name,
                })),
            ]}
            onChange={(val: string) => props.onCategorySelect(val)}
        />
    );
}
