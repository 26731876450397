import axios from 'axios';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

import {
    LoginRequest,
    LoginResponse,
    RefreshTokenResponse,
} from '../types/authentication.type';
import { User } from '../types/user.type';

axios.defaults.baseURL =
    process.env.REACT_APP_API_URL || 'http://localhost:4000/';
// axios.defaults.baseURL = "https://api.rotaileshop/"
axios.defaults.headers['content-type'] = 'application/json';

interface UserContext {
    isLoaded: boolean;
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    logout: () => void;
    login: (creds: LoginRequest) => Promise<void>;
}

const UserContext = createContext<UserContext | undefined>(undefined);
interface UserProviderProps {
    children: ReactNode;
}

export async function refreshToken(
    token: string
): Promise<RefreshTokenResponse> {
    if (!token || token.length == 0) {
        throw new Error('Unauthorized!');
    }

    const response = await axios.post<RefreshTokenResponse>(
        '/authentication/refresh-token',
        {
            headers: {
                'x-authorization': token,
            },
        }
    );
    if (response.status !== 200) {
        throw new Error('Unauthorized!');
    }

    return response.data;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const [user, setUser] = useState<User | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const checkAuth = useCallback(
        debounce(async () => {
            if (!token || token.length == 0) {
                setIsLoaded(true);
                return;
            }

            try {
                const res = await axios.get<User>('/authentication/me', {
                    headers: {
                        'x-authorization': token,
                    },
                });

                if (res.status === 200) {
                    setUser(res.data);
                }
            } catch (error) {
                console.error('Authentication error:', error);

                try {
                    const res = await refreshToken(token);
                    setUser(res.user);
                    setToken(res.token);
                } catch {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    navigate('/');
                }
            } finally {
                setIsLoaded(true);
            }
        }, 150),
        [token]
    );
    const logout = useCallback(() => {
        setUser(null);
        setToken('');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        axios.interceptors.response.clear();
        navigate('/login');
    }, [user, token, isLoaded]);
    const login = useCallback(
        async (payload: LoginRequest) => {
            const res = await axios.post<LoginResponse>(
                '/authentication/login',
                payload
            );
            setUser(res.data.user);
            setToken(res.data.token);
            setIsLoaded(true);
        },
        [axios]
    );

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        if (!token) return;
        localStorage.setItem('token', token);
        axios.defaults.headers['x-authorization'] = token;
        axios.interceptors.response.clear();
        axios.interceptors.response.use(
            (res) => res,
            (error) => {
                if (error.response && error.response.status === 401) {
                    if (error.config.url === '/authentication/refresh-token') {
                        navigate('/');
                        return Promise.resolve();
                    }

                    return refreshToken(token)
                        .then((res) => {
                            setToken(res.token);
                            setUser(res.user);
                            localStorage.setItem('token', res.token);
                            return axios({
                                ...error.config,
                                headers: {
                                    ...(error.config.headers || {}),
                                    'x-authorization': res.token,
                                },
                            });
                        })
                        .catch((err) => {
                            navigate('/');
                            setToken('');
                            setUser(null);
                            localStorage.setItem('token', '');
                            localStorage.setItem('user', '');
                            axios.interceptors.response.clear();
                            return Promise.reject(err);
                        });
                }

                return Promise.reject(error);
            }
        );
        axios.interceptors.request.clear();
        axios.interceptors.request.use((config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['x-authorization'] = token;
            }
            return config;
        });
    }, [axios, token]);

    return (
        <UserContext.Provider
            value={{ login, user, setUser, isLoaded, logout }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
