import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Table } from '../../components/Table/Table';
import { MailingItem } from '../../types/mailing.type';

function renderHeaders() {
    return (
        <thead>
            <tr>
                <th>Konu</th>
                <th>Basarili Gonderim</th>
                <th>Basarisiz Gonderim</th>
            </tr>
        </thead>
    );
}

export function MailingList() {
    const [items, setItems] = useState<Array<MailingItem>>([]);
    const renderItems = useCallback((item: MailingItem) => {
        return (
            <tr key={item.id}>
                <td>{item.subject}</td>
                <td>{item.successfullCount}</td>
                <td>{item.failedCount}</td>
            </tr>
        );
    }, []);

    const fetchMailingItems = useCallback(async () => {
        const res = await axios('/mailing');
        setItems(res.data);
    }, [axios]);

    useEffect(() => {
        fetchMailingItems();
    }, []);

    return (
        <div>
            <h2>Ürünler</h2>
            <Table
                renderItems={renderItems}
                renderHeaders={renderHeaders}
                items={items}
            />
        </div>
    );
}
