import { Category } from './category.type';
import { ProductFile } from './productFile.type';
import { ProductSize } from './productSize.type';

export enum ProductStates {
    ACTIVE = 'ACTIVE',
    DEACTIVE = 'DEACTIVE',
}

export enum DiscountTypes {
    PERCENT = 'PERCENT',
    AMOUNT = 'AMOUNT',
}

export interface CreateProductRequest {
    name: string;
    description: string;
    categoryId: string;
    badge?: string;
    state: ProductStates;
    price: number;
    abroadPrice: number;
    discountType?: DiscountTypes;
    discountAmount?: number;
    freeShipment?: boolean;
}

export interface UpdateProductRequest {
    name?: string;
    description?: string;
    categoryId?: string;
    badge?: string;
    state?: ProductStates;
    price?: number;
    abroadPrice?: number;
    discountType?: DiscountTypes;
    discountAmount?: number;
    freeShipment?: boolean;
}

// Response DTOs
export interface ProductSummary {
    id: string;
    name: string;
    description: string;
    badge: string;
    state: ProductStates;
    price: number;
    abroadPrice: number;
    discountType?: DiscountTypes;
    discountAmount?: number;
    freeShipment?: boolean;
}

export interface ProductDetail {
    id: string;
    name: string;
    description: string;
    badge: string;
    state: ProductStates;
    price: number;
    abroadPrice: number;
    discountType?: DiscountTypes;
    discountAmount?: number;
    freeShipment?: boolean;
    files: ProductFile[];
    category: Category;
    sizes: ProductSize[];
}
