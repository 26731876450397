import axios from 'axios';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CategorySelect } from '../../components/CategorySelect/CategorySelect';
import { Form } from '../../components/Form/Form';
import {
    CurrencyInput,
    HTMLInput,
    SelectInput,
    TextInput,
} from '../../components/Input/Input';
import { CreateProductRequest, ProductStates } from '../../types/product.type';
import styles from './CreateProduct.module.css';

export function CreateProduct() {
    const navigate = useNavigate();
    const [payload, setPayload] = useState<CreateProductRequest>({
        name: '',
        description: '',
        categoryId: '',
        badge: '',
        state: ProductStates.DEACTIVE,
        price: 0,
        abroadPrice: 0,
    });
    const onFieldChange = useCallback(
        function <K extends keyof CreateProductRequest>(
            key: K,
            val: CreateProductRequest[K]
        ) {
            setPayload({
                ...payload,
                [key]: val,
            });
        },
        [payload]
    );

    const onSaveProduct = useCallback(async () => {
        if (Number(payload.price) <= 0 || Number.isNaN(payload.price)) {
            alert(
                'Urun fiyati hatali! Urun fiyati bu sekilde girilmeli: 299.99'
            );
            return;
        }
        if (
            Number(payload.abroadPrice) <= 0 ||
            Number.isNaN(payload.abroadPrice)
        ) {
            alert(
                'Urun yurtdisi fiyati hatali! Urun fiyati bu sekilde girilmeli: 299.99'
            );
            return;
        }
        if (payload.categoryId.length === 0) {
            alert('Kategori bos birakilamaz!');
            return;
        }
        if (payload.name.length === 0) {
            alert('Urun ismi bos birakilamaz!');
            return;
        }
        if (payload.description.length === 0) {
            alert('Urun aciklamasi bos birakilamaz!');
            return;
        }

        try {
            const res = await axios.post('/products', {
                ...payload,
            });

            alert('Urun basariyla olusturuldu!');
            navigate(`/products/${res.data.id}/detail`);
        } catch (error) {
            alert('Urun olusuturulurken bir sorun olustu!');
            return;
        }
    }, [axios, payload]);

    return (
        <div className={styles.container}>
            <h2>Urun Olustur</h2>

            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: onSaveProduct,
                    },
                ]}
                onSubmit={onSaveProduct}
            >
                <TextInput
                    label="Isim:"
                    defaultValue={payload.name}
                    onChange={(val) => onFieldChange('name', val)}
                />
                <CategorySelect
                    value={payload.categoryId}
                    onCategorySelect={(val: string) =>
                        onFieldChange('categoryId', val)
                    }
                />
                <HTMLInput
                    fullWidth
                    label="Aciklama:"
                    value={payload.description}
                    onChange={(val) => onFieldChange('description', val)}
                />
                <TextInput
                    label="Badge:"
                    defaultValue={payload.badge}
                    onChange={(val) => onFieldChange('badge', val)}
                />
                <SelectInput
                    label="Durum:"
                    value={payload.state}
                    items={[
                        {
                            val: ProductStates.DEACTIVE,
                            label: 'Deaktif',
                        },
                        {
                            val: ProductStates.ACTIVE,
                            label: 'Aktif',
                        },
                    ]}
                    onChange={(val) => onFieldChange('state', val)}
                />
                <CurrencyInput
                    label="Fiyat:"
                    defaultValue={payload.price}
                    onChange={(val) => onFieldChange('price', val)}
                />
                <CurrencyInput
                    label="Yurtdisi Fiyati:"
                    defaultValue={payload.abroadPrice}
                    onChange={(val) => onFieldChange('abroadPrice', val)}
                />
            </Form>
        </div>
    );
}
