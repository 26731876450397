import styles from './ShipmentOrderList.module.css';

import * as XLSX from 'xlsx';

import axios from 'axios';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../components/Table/Table';
import {
    IOrderDetail,
    OrderStates,
    OrderSummary,
    PaginatedOrders,
    PaginateOrderFilters,
    PaymentMethods,
} from '../../types/order.type';
import { PaginationParams } from '../../types/pagination.type';
import { formatDate } from '../../utils/formatDate';
import { getOrderState } from '../../utils/orderState';
import { getPaymentMethod } from '../../utils/paymentMethods';
import { Form } from '../../components/Form/Form';
const randomFourDigitString = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
};

function renderHeaders() {
    return (
        <thead>
            <tr>
                <th>İsim</th>
                <th>Ödeme Yöntemi</th>
                <th>Siparis Durumu</th>
                <th>Not</th>
                <th>Urunler</th>
                <th>Aksiyon</th>
            </tr>
        </thead>
    );
}

export function ShipmentOrderList() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState<PaginatedOrders>({
        page: 1,
        limit: 10,
        items: [],
        totalPages: 1,
    });
    const [pagination, setPagination] = useState<PaginationParams>({
        limit: 100,
        page: 1,
    });
    const [filters, setFilters] = useState<PaginateOrderFilters>({
        states: [
            OrderStates.PREPARING,
            OrderStates.CREATED,
            OrderStates.WAITING_APPROVE,
            OrderStates.PAYMENT_COMPLETED,
        ],
    });
    const fetchOrders = useCallback(
        debounce(async () => {
            try {
                const res = await axios<PaginatedOrders>(`/orders`, {
                    params: {
                        ...filters,
                        ...pagination,
                    },
                });

                setOrders(res.data);
            } catch (error) {
                console.error(
                    `Something went wrong during fetch orders: `,
                    error
                );
                alert('Siparisler getirilirken bir sorun olustu!');
            }
        }, 250),
        [pagination, filters]
    );
    const onOrderStateChange = useCallback(
        async (id: string, state: OrderStates) => {
            try {
                await axios.patch(`/orders/${id}/state`, {
                    state,
                });
                await fetchOrders();
            } catch (error) {
                console.error(
                    `Something went wrong during update order state: `,
                    error
                );
                alert('Siparisler guncellenirken bir sorun olustu!');
            }
        },
        []
    );

    const renderItems = useCallback((item: IOrderDetail, index: number) => {
        return (
            <tr key={index}>
                <td>
                    {item.customerFirstName} {item.customerLastName}
                </td>
                <td>{getPaymentMethod(item.paymentMethod)}</td>
                <td>{getOrderState(item.state)}</td>
                <td>{item.note}</td>
                <td>
                    <ul>
                        {item.products.map((ps) => (
                            <li key={ps.id}>
                                {ps.quantity} Adet:
                                {ps.productSize.product.name}{' '}
                                {ps.productSize.name}
                            </li>
                        ))}
                    </ul>
                </td>
                <td
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className={styles.listActions}>
                        {[
                            OrderStates.CREATED,
                            OrderStates.WAITING_APPROVE,
                            OrderStates.PAYMENT_COMPLETED,
                        ].includes(item.state) && (
                            <>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.PREPARING
                                        );
                                    }}
                                    className={styles.orange}
                                >
                                    Hazirlaniyor
                                </button>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.REJECTED
                                        );
                                    }}
                                    className={styles.red}
                                >
                                    Iptal Edildi
                                </button>
                            </>
                        )}
                        {OrderStates.PREPARING === item.state && (
                            <>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.SHIPPED
                                        );
                                    }}
                                    className={styles.orange}
                                >
                                    Kargolandi
                                </button>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.REJECTED
                                        );
                                    }}
                                    className={styles.red}
                                >
                                    Iptal Edildi
                                </button>
                            </>
                        )}
                        {OrderStates.SHIPPED === item.state && (
                            <button
                                onClick={() => {
                                    onOrderStateChange(
                                        item.id,
                                        OrderStates.COMPLETED
                                    );
                                }}
                                className={styles.green}
                            >
                                Tamamlandi
                            </button>
                        )}
                        {OrderStates.COMPLETED === item.state && (
                            <button
                                onClick={() => {
                                    onOrderStateChange(
                                        item.id,
                                        OrderStates.REFUNDED
                                    );
                                }}
                                className={styles.red}
                            >
                                Iade edildi
                            </button>
                        )}
                    </div>
                </td>
            </tr>
        );
    }, []);
    const downloadExcel = useCallback(() => {
        const headers = [
            'isim',
            'adres',
            'il',
            'ilce',
            'ev_telefonu',
            'cep_telefonu',
            'eposta',
            'vergi_no',
            'kargo_turu',
            'odeme_tipi',
            'irsaliye_numarasi',
            'referans_numarasi',
            'adet',
            'kargo_icerigi',
            'tahsilat_tipi',
            'fatura_numarasi',
            'fatura_tutari',
            'dosya_poset_no',
            'kampanya_no',
            'kampanya_kodu',
        ];

        const data = orders.items.map((row) => [
            `${row.customerFirstName} ${row.customerLastName}`,
            row.shipmentAddress,
            row.shipmentCity,
            row.shipmentDistrict,
            '',
            row.customerPhoneNumber,
            '',
            '',
            2, // kargo turu
            1,
            '',
            '',
            1,
            '',
            '',
            row.paymentMethod === PaymentMethods.ON_DELIVERY ? '0' : '',

            row.paymentMethod === PaymentMethods.ON_DELIVERY
                ? Math.ceil(row.discountedTotalPrice + row.shipmentFee)
                : '',
            row.paymentMethod === PaymentMethods.ON_DELIVERY
                ? randomFourDigitString()
                : '',
            '',
            '',
            '',
        ]);

        const worksheetData = [headers, ...data];

        // Create a worksheet and workbook
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Siparişler');

        // Generate XLSX file
        const excelFile = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Create a blob and download
        const blob = new Blob([excelFile], {
            type: 'application/octet-stream',
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'siparisler.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }, [orders.items]);

    useEffect(() => {
        fetchOrders();
    }, [pagination, filters]);

    return (
        <>
            <h1>Kargo Bekleyen Siparisler</h1>
            <Form>
                <button
                    style={{ marginTop: 16, height: 32 }}
                    onClick={downloadExcel}
                >
                    Excel Indir
                </button>
            </Form>
            <Table
                renderItems={renderItems}
                renderHeaders={renderHeaders}
                items={orders.items}
                pagination={pagination}
                onPaginationChange={setPagination}
                totalPages={orders.totalPages}
            />
        </>
    );
}
