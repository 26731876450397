import axios from 'axios';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '../../components/Form/Form';
import { TextAreaInput, TextInput } from '../../components/Input/Input';
import { Table } from '../../components/Table/Table';
import { IOrderDetail, OrderProductDetail } from '../../types/order.type';
import { formatDate } from '../../utils/formatDate';
import {
    getDiscountAmount,
    getDiscountType,
} from '../../utils/getDiscountType';
import { getOrderState } from '../../utils/orderState';

function renderProductsHeaders() {
    return (
        <tr>
            <th>Urun Adi</th>
            <th>Urun Bedeni</th>
            <th>Adet</th>
            <th>Ucretsiz Kargo</th>
            <th>Parca basi fiyat</th>
            <th>Indirim Cesidi</th>
            <th>Indirim Tutari</th>
        </tr>
    );
}

export function OrderDetail() {
    const { orderId } = useParams();
    const [order, setOrder] = useState<IOrderDetail>();
    const fetchOrder = useCallback(
        debounce(async () => {
            try {
                const res = await axios<IOrderDetail>(`/orders/${orderId}`);
                setOrder(res.data);
            } catch (error) {
                console.error(
                    `Something went wrong during get order detail: `,
                    error
                );
                alert('Siparis getirilemedi!');
            }
        }, 250),
        [orderId]
    );

    const renderProducts = useCallback(
        (item: OrderProductDetail) => {
            return (
                <tr>
                    <td>{item.productSize.product.name}</td>
                    <td>{item.productSize.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.freeShipment ? 'Evet' : 'Hayir'}</td>
                    <td>
                        {item.itemPrice.toFixed(2)} {order?.currencyCode}
                    </td>
                    <td>{getDiscountType(item.discountType)}</td>
                    <td>
                        {getDiscountAmount(
                            item.discountAmount,
                            order?.currencyCode,
                            item.discountType
                        )}
                    </td>
                </tr>
            );
        },
        [order]
    );

    useEffect(() => {
        fetchOrder();
    }, []);

    if (!order) {
        return (
            <>
                <h2>Yukleniyor...</h2>
            </>
        );
    }

    return (
        <>
            <h1>Sipariş Detayı</h1>
            <Form actions={[]}>
                <TextInput
                    label="İsim:"
                    value={`${order.customerFirstName} ${order.customerLastName}`}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="E-posta adresi:"
                    value={order.customerEmail}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Telefon numarası:"
                    value={order.customerPhoneNumber}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Sipariş durumu:"
                    value={getOrderState(order.state)}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Iyzico islem kodu:"
                    value={order.externalPaymentId || '-'}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Toplam Tutar:"
                    value={`${order.totalPrice.toString()} ${order.currencyCode}`}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Indirimli Tutar:"
                    value={`${order.discountedTotalPrice.toString()} ${order.currencyCode}`}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Kargo Ucreti:"
                    value={`${order.shipmentFee.toString()} ${order.currencyCode}`}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Adres:"
                    value={`${order.shipmentAddress} ${order.shipmentDistrict} ${order.shipmentCity} ${order.shipmentCountry}`}
                    onChange={() => {}}
                    isDisabled
                />
                <TextInput
                    label="Sipariş Tarihi:"
                    value={formatDate(order.createdAt)}
                    onChange={() => {}}
                    isDisabled
                />
                <TextAreaInput
                    fullWidth
                    rows={5}
                    label="Sipariş notu:"
                    value={order.note}
                    onChange={() => {}}
                    isDisabled
                />
            </Form>
            <h2>Ürünler</h2>
            <Table
                items={order.products}
                renderItems={renderProducts}
                renderHeaders={renderProductsHeaders}
            />
        </>
    );
}
