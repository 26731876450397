import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/Form/Form';
import { PasswordInput, TextInput } from '../../components/Input/Input';
import { useUser } from '../../context/user.context';
import { LoginRequest } from '../../types/authentication.type';
import styles from './Login.module.css';

export function Login() {
    const navigate = useNavigate();
    const { login } = useUser();
    const [creds, setCreds] = useState<LoginRequest>({
        email: '',
        password: '',
    });

    const onFieldChange = useCallback(
        function <K extends keyof LoginRequest>(key: K, val: LoginRequest[K]) {
            setCreds({
                ...creds,
                [key]: val,
            });
        },
        [creds]
    );
    const onLogin = useCallback(async () => {
        try {
            await login(creds);
            navigate('/');
        } catch (error) {
            alert('Bilgiler hatali!');
        }
    }, [login, creds]);

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <h1>Giriş</h1>
                <Form
                    fullWidth
                    actions={[
                        {
                            text: 'Giriş',
                            onClick: onLogin,
                        },
                    ]}
                >
                    <TextInput
                        label="E-Posta"
                        onChange={(val: string) => onFieldChange('email', val)}
                    />
                    <PasswordInput
                        label="Parola"
                        onChange={(val: string) =>
                            onFieldChange('password', val)
                        }
                    />
                </Form>
            </div>
        </div>
    );
}
