import styles from './Navbar.module.css';

import { Link } from 'react-router-dom';
import { DownArrow } from '../DownArrow/DownArrow';
import { useCallback, useState } from 'react';

const menuData = [
    {
        id: 1,
        title: 'Kategoriler',
        links: [
            { path: '/categories', label: 'Kategori Listesi' },
            { path: '/categories/create', label: 'Kategori Ekle' },
        ],
    },
    {
        id: 2,
        title: 'Ürünler',
        links: [
            { path: '/products', label: 'Ürün Listesi' },
            { path: '/products/create', label: 'Ürün Ekle' },
        ],
    },
    {
        id: 3,
        title: 'Siparişler',
        links: [
            { path: '/orders', label: 'Sipariş Listesi' },
            {
                path: '/orders/waiting-for-shipment',
                label: 'Kargo Bekleyen Siparişler',
            },
        ],
    },
    {
        id: 4,
        title: 'E-Posta',
        links: [
            { path: '/mailings', label: 'E-Posta Gonderileri' },
            {
                path: '/email/send',
                label: 'E-Posta Gönder',
            },
        ],
    },
    {
        id: 5,
        title: 'Sistem Ayarları',
        links: [{ path: '/site-config', label: 'Sistem Ayarlari' }],
    },
];

export function Navbar() {
    const [openMenus, setOpenMenus] = useState<Array<number>>([]);

    const toggleMenu = useCallback(
        (id: number) => {
            if (openMenus.includes(id)) {
                setOpenMenus([...openMenus.filter((x) => x !== id)]);
                return;
            }

            setOpenMenus([...openMenus, id]);
        },
        [openMenus]
    );

    return (
        <div className={styles.container}>
            <h2>RotaileShop</h2>

            <div className={styles.menu}>
                {menuData.map((menuItem) => (
                    <div
                        key={menuItem.id}
                        className={`${styles.menuItem} ${openMenus.includes(menuItem.id) ? styles.active : ''}`}
                    >
                        <div
                            className={styles.menuText}
                            onClick={() => toggleMenu(menuItem.id)}
                        >
                            {menuItem.title} <DownArrow />
                        </div>

                        {openMenus.includes(menuItem.id) && (
                            <div className={styles.subMenu}>
                                {menuItem.links.map((link, index) => (
                                    <div
                                        key={index}
                                        className={styles.subMenuItem}
                                    >
                                        <Link to={link.path}>{link.label}</Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
