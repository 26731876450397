export function formatDate(date: Date): string {
    const d = new Date(date);

    const dateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    const formattedDate = d.toLocaleDateString('en-GB', dateOptions);
    const formattedTime = d.toLocaleTimeString(undefined, timeOptions);

    return `${formattedDate} ${formattedTime}`;
}
