import axios from 'axios';
import { useCallback, useState } from 'react';
import { CitySelectInput } from '../../components/CitySelect';
import { Form } from '../../components/Form/Form';
import {
    CheckboxInput,
    FileInput,
    TextInput,
} from '../../components/Input/Input';
import styles from './SendEmail.module.css';

export function SendEmail() {
    const [payload, setPayload] = useState({
        subject: '',
        link: '',
        city: '',
        isTest: false,
    });
    const [file, setFile] = useState<File | null>(null);

    const onSubmit = useCallback(async () => {
        if (!file) {
            alert('Dosya secilmedi!');
            return;
        }
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('subject', payload.subject);
            formData.append('city', payload.city);
            formData.append('link', payload.link);
            formData.append('isTest', payload.isTest.toString());

            await axios.post('/mailing', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Mail basariyla yuklendi!');
        } catch (error) {
            console.error('Something went wrong during send mailing: ', error);
            alert('Mail gonderimi basarisiz!');
        }
    }, [payload, file]);

    return (
        <>
            <div className={styles.container}>
                <h2>Mail Gonder</h2>

                <Form
                    actions={[
                        {
                            text: 'Kaydet',
                            onClick: onSubmit,
                        },
                    ]}
                    onSubmit={onSubmit}
                >
                    <TextInput
                        label="Konu:"
                        value={payload.subject}
                        onChange={(val) =>
                            setPayload({
                                ...payload,
                                subject: val,
                            })
                        }
                    />
                    <TextInput
                        label="Link:"
                        value={payload.link}
                        onChange={(val) =>
                            setPayload({
                                ...payload,
                                link: val,
                            })
                        }
                    />
                    <CitySelectInput
                        value={payload.city}
                        onChange={(val) =>
                            setPayload({
                                ...payload,
                                city: val,
                            })
                        }
                    />
                    <FileInput
                        label="Dosya:"
                        onChange={setFile}
                        fileTypes={['image/png', 'image/jpeg']}
                    />
                    <CheckboxInput
                        label="Test Gonderimi:"
                        checked={payload.isTest}
                        onChange={(val) =>
                            setPayload({
                                ...payload,
                                isTest: val,
                            })
                        }
                    />
                </Form>
            </div>
        </>
    );
}
