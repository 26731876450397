import { OrderList } from '../../components/OrderList/OrderList';

export function PageOrderList() {
    return (
        <>
            <h1>Siparisler</h1>
            <OrderList />
        </>
    );
}
