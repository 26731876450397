import styles from './OrderList.module.css';

import axios from 'axios';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../components/Table/Table';
import {
    OrderStates,
    OrderSummary,
    PaginatedOrders,
    PaginateOrderFilters,
} from '../../types/order.type';
import { PaginationParams } from '../../types/pagination.type';
import { formatDate } from '../../utils/formatDate';
import { getOrderState } from '../../utils/orderState';
import { getPaymentMethod } from '../../utils/paymentMethods';

function renderHeaders() {
    return (
        <thead>
            <tr>
                <th>İsim</th>
                <th>Ödeme Yöntemi</th>
                <th>Siparis Durumu</th>
                <th>Siparis Olusturulma Tarihi</th>
                <th>Aksiyon</th>
            </tr>
        </thead>
    );
}

interface OrderListProps {
    states?: Array<OrderStates>;
}

export function OrderList(props: OrderListProps) {
    const navigate = useNavigate();
    const [orders, setOrders] = useState<PaginatedOrders>({
        page: 1,
        limit: 10,
        items: [],
        totalPages: 1,
    });
    const [pagination, setPagination] = useState<PaginationParams>({
        limit: 10,
        page: 1,
    });
    const [filters, setFilters] = useState<PaginateOrderFilters>({
        states: props.states,
    });
    const fetchOrders = useCallback(
        debounce(async () => {
            try {
                const res = await axios<PaginatedOrders>(`/orders`, {
                    params: {
                        ...filters,
                        ...pagination,
                    },
                });

                setOrders(res.data);
            } catch (error) {
                console.error(
                    `Something went wrong during fetch orders: `,
                    error
                );
                alert('Siparisler getirilirken bir sorun olustu!');
            }
        }, 250),
        [pagination, filters]
    );
    const onOrderStateChange = useCallback(
        async (id: string, state: OrderStates) => {
            try {
                await axios.patch(`/orders/${id}/state`, {
                    state,
                });
                await fetchOrders();
            } catch (error) {
                console.error(
                    `Something went wrong during update order state: `,
                    error
                );
                alert('Siparisler guncellenirken bir sorun olustu!');
            }
        },
        []
    );

    const renderItems = useCallback((item: OrderSummary, index: number) => {
        return (
            <tr
                key={index}
                onClick={() => navigate(`/orders/${item.id}/detail`)}
            >
                <td>
                    {item.customerFirstName} {item.customerLastName}
                </td>
                <td>{getPaymentMethod(item.paymentMethod)}</td>
                <td>{getOrderState(item.state)}</td>
                <td>{formatDate(item.createdAt)}</td>
                <td
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className={styles.listActions}>
                        {[
                            OrderStates.CREATED,
                            OrderStates.WAITING_APPROVE,
                            OrderStates.PAYMENT_COMPLETED,
                        ].includes(item.state) && (
                            <>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.PREPARING
                                        );
                                    }}
                                    className={styles.orange}
                                >
                                    Hazirlaniyor
                                </button>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.REJECTED
                                        );
                                    }}
                                    className={styles.red}
                                >
                                    Iptal Edildi
                                </button>
                            </>
                        )}
                        {OrderStates.PREPARING === item.state && (
                            <>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.SHIPPED
                                        );
                                    }}
                                    className={styles.orange}
                                >
                                    Kargolandi
                                </button>
                                <button
                                    onClick={() => {
                                        onOrderStateChange(
                                            item.id,
                                            OrderStates.REJECTED
                                        );
                                    }}
                                    className={styles.red}
                                >
                                    Iptal Edildi
                                </button>
                            </>
                        )}
                        {OrderStates.SHIPPED === item.state && (
                            <button
                                onClick={() => {
                                    onOrderStateChange(
                                        item.id,
                                        OrderStates.COMPLETED
                                    );
                                }}
                                className={styles.green}
                            >
                                Tamamlandi
                            </button>
                        )}
                        {OrderStates.COMPLETED === item.state && (
                            <button
                                onClick={() => {
                                    onOrderStateChange(
                                        item.id,
                                        OrderStates.REFUNDED
                                    );
                                }}
                                className={styles.red}
                            >
                                Iade edildi
                            </button>
                        )}
                    </div>
                </td>
            </tr>
        );
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [pagination, filters]);

    return (
        <>
            <Table
                renderItems={renderItems}
                renderHeaders={renderHeaders}
                items={orders.items}
                pagination={pagination}
                onPaginationChange={setPagination}
                totalPages={orders.totalPages}
            />
        </>
    );
}
