import React, { useState } from 'react';

import styles from './Tabs.module.css';

export interface TabsProps {
    headers: Array<React.ReactNode>;
    contents: Array<React.ReactNode>;
}
export function Tabs(props: TabsProps) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className={styles.tabs}>
            <div className={styles.tabButtons}>
                {props.headers.map((item, index) => (
                    <button
                        key={index}
                        className={`${styles.tabButton} ${activeTab === index ? styles.active : ''}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {item}
                    </button>
                ))}
            </div>
            <div className={styles.tabContent}>{props.contents[activeTab]}</div>
        </div>
    );
}
