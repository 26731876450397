import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CategorySelect } from '../../components/CategorySelect/CategorySelect';
import { Form } from '../../components/Form/Form';
import {
    CheckboxInput,
    CurrencyInput,
    FileInput,
    HTMLInput,
    NumberInput,
    SelectInput,
    TextInput,
} from '../../components/Input/Input';
import { Table } from '../../components/Table/Table';
import { Tabs } from '../../components/Tabs/Tabs';
import { CategoryStates } from '../../types/category.type';
import {
    DiscountTypes,
    ProductDetail,
    ProductStates,
    UpdateProductRequest,
} from '../../types/product.type';
import { ProductSize, ProductSizeStates } from '../../types/productSize.type';
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
    SortEnd,
} from 'react-sortable-hoc';

import styles from './ProductDetail.module.css';

const imagePath = process.env.REACT_APP_API_URL || 'http://localhost:4000/';

interface ProductInformationsProps {
    product: ProductDetail;
    refreshProduct: () => Promise<void>;
}
function ProductInformations(props: ProductInformationsProps) {
    const [payload, setPayload] = useState<UpdateProductRequest>({});
    const onSave = useCallback(async () => {
        await axios.patch(`/products/${props.product.id}`, payload);
        await props.refreshProduct();
        alert('Urun basariyla guncellendi!');
    }, [props.product.id, payload]);
    const onFieldChange = useCallback(
        function <K extends keyof UpdateProductRequest>(
            key: K,
            val: UpdateProductRequest[K]
        ) {
            setPayload({
                ...payload,
                [key]: val,
            });
        },
        [payload]
    );
    const totalPrice = useMemo(() => {
        const price = payload.price || props.product.price;
        const discountType = payload.discountType || props.product.discountType;
        const discountAmount =
            payload.discountAmount || props.product.discountAmount;

        if (!price) return 0;

        if (discountType === DiscountTypes.AMOUNT) {
            return price ? price - (discountAmount || 0) : 0;
        } else if (discountType === DiscountTypes.PERCENT) {
            const discounted = discountAmount ? discountAmount / 100 : 0;
            return price - price * discounted;
        } else {
            return price;
        }
    }, [props.product, payload]);

    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            discountType: props.product.discountType,
        }));
    }, [props.product.discountType]);

    return (
        <>
            <h2>Ürün Detayi</h2>
            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: onSave,
                    },
                ]}
                onSubmit={onSave}
            >
                <TextInput
                    label="Isim:"
                    value={
                        payload.name === undefined
                            ? props.product.name
                            : payload.name
                    }
                    onChange={(val) => onFieldChange('name', val)}
                />
                <CategorySelect
                    value={
                        payload.categoryId === undefined
                            ? props.product.category.id
                            : payload.categoryId
                    }
                    onCategorySelect={(val: string) =>
                        onFieldChange('categoryId', val)
                    }
                />
                <HTMLInput
                    fullWidth
                    label="Aciklama:"
                    value={
                        payload.description === undefined
                            ? props.product.description
                            : payload.description
                    }
                    onChange={(val) => onFieldChange('description', val)}
                />
                <TextInput
                    label="Badge:"
                    value={
                        payload.badge === undefined
                            ? props.product.badge
                            : payload.badge
                    }
                    onChange={(val) => onFieldChange('badge', val)}
                />
                <SelectInput
                    label="Durum:"
                    value={
                        payload.state === undefined
                            ? props.product.state
                            : payload.state
                    }
                    items={[
                        {
                            val: ProductStates.DEACTIVE,
                            label: 'Deaktif',
                        },
                        {
                            val: ProductStates.ACTIVE,
                            label: 'Aktif',
                        },
                    ]}
                    onChange={(val) => onFieldChange('state', val)}
                />
                <CurrencyInput
                    label="Fiyat:"
                    defaultValue={props.product.price}
                    onChange={(val) => onFieldChange('price', val)}
                />
                <CurrencyInput
                    label="Yurtdisi Fiyati:"
                    defaultValue={props.product.abroadPrice}
                    onChange={(val) => onFieldChange('abroadPrice', val)}
                />
                <SelectInput
                    label="Indirim Cesidi:"
                    defaultValue={props.product.discountType}
                    value={payload.discountType || ''}
                    items={[
                        {
                            val: '',
                            label: 'Seciniz',
                        },
                        {
                            val: DiscountTypes.PERCENT,
                            label: 'Yuzde',
                        },
                        {
                            val: DiscountTypes.AMOUNT,
                            label: 'Tutar',
                        },
                    ]}
                    onChange={(val) =>
                        onFieldChange(
                            'discountType',
                            val === ''
                                ? ('' as DiscountTypes)
                                : (val as DiscountTypes)
                        )
                    }
                />
                <NumberInput
                    label="Indirim Tutari:"
                    defaultValue={props.product.discountAmount || 0}
                    onChange={(val) => onFieldChange('discountAmount', val)}
                />
                <CheckboxInput
                    label="Ucretsiz Kargo:"
                    onChange={(val) => onFieldChange('freeShipment', val)}
                    defaultChecked={props.product.freeShipment}
                />
                <TextInput
                    label="Satis Fiyati:"
                    value={totalPrice.toFixed(2)}
                    onChange={() => {}}
                    isDisabled
                />
            </Form>
        </>
    );
}

function renderProductSizeHeaders() {
    return (
        <thead>
            <tr>
                <th>Beden</th>
                <th>Stok</th>
                <th>Aksiyon</th>
            </tr>
        </thead>
    );
}

interface ProductSizesProps {
    product: ProductDetail;
    refreshProduct: () => Promise<void>;
}

function ProductSizes({ product, refreshProduct }: ProductSizesProps) {
    const [newSize, setNewSize] = useState('');
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
    const onSaveSize = useCallback(async () => {
        if (newSize.length === 0) {
            alert('Beden ismi bos birakilamaz!');
            return;
        }

        try {
            await axios.post('/product-sizes', {
                productId: product.id,
                name: newSize,
                state: ProductSizeStates.ACTIVE,
            });
            await refreshProduct();
            setNewSize('');
            alert('Beden basariyla eklendi!');
        } catch (error) {
            console.error(
                'Something went wrong during create new size: ',
                error
            );
            alert('Beden eklenirken bir sorun olustu!');
        }
    }, [product, newSize]);
    const onUpdateSize = useCallback(
        async (sizeId: string, quantity: number) => {
            await axios.post('/product-size-stocks', {
                productSizeId: sizeId,
                quantity,
            });
            await refreshProduct();
            setQuantities({});
        },
        [axios, quantities]
    );
    const onQuantityChange = useCallback(
        (key: string, value: number) => {
            setQuantities({
                ...quantities,
                [key]: value,
            });
        },
        [quantities]
    );
    const onSizeRemove = useCallback(async (sizeId: string) => {
        try {
            await axios.delete(`/product-sizes/${sizeId}`);
            await refreshProduct();
            alert('Beden basariyla silindi!');
        } catch (error) {
            console.error('Something went wrong during remove size: ', error);
            alert('Beden silinirken bir sorun olustu!');
        }
    }, []);

    const renderItems = useCallback(
        (item: ProductSize, index: number) => {
            return (
                <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                        <div className={styles.tableActionWrapper}>
                            <input
                                type="number"
                                value={
                                    quantities[item.id] === undefined
                                        ? item.stocks.reduce(
                                              (t, c) => t + c.quantity,
                                              0
                                          )
                                        : quantities[item.id]
                                }
                                onChange={(e) => {
                                    const val = Number(e.target.value);
                                    const newValue = Number.isNaN(val)
                                        ? 0
                                        : val;
                                    onQuantityChange(item.id, newValue);
                                }}
                            />
                            <button
                                onClick={() => {
                                    onUpdateSize(item.id, quantities[item.id]);
                                }}
                            >
                                Kaydet
                            </button>
                        </div>
                    </td>
                    <td className={styles.tableDeleteColumn}>
                        <button onClick={() => onSizeRemove(item.id)}>
                            Sil
                        </button>
                    </td>
                </tr>
            );
        },
        [product, quantities]
    );

    return (
        <div>
            <h2>Beden Olustur</h2>
            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: onSaveSize,
                    },
                ]}
                onSubmit={onSaveSize}
            >
                <TextInput
                    label="Isim:"
                    value={newSize}
                    onChange={setNewSize}
                />
            </Form>
            <h2>Bedenler</h2>

            <Table
                renderItems={renderItems}
                renderHeaders={renderProductSizeHeaders}
                items={product.sizes}
            />
        </div>
    );
}

interface ProductImagesProps {
    product: ProductDetail;
    refreshProduct: () => Promise<void>;
}

function ProductImages(props: ProductImagesProps) {
    const [file, setFile] = useState<File | null>(null);
    const [images, setImages] = useState<ProductDetail['files']>(
        props.product.files
    );
    const onSubmit = useCallback(async () => {
        if (!file) {
            alert('Dosya secilmedi!');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        await axios.post(`/products/${props.product.id}/files`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        await props.refreshProduct();
        alert('Dosya basariyla yuklendi!');
        setFile(null);
    }, [props.product.id, file]);
    const onSortEnd = useCallback(
        async ({ oldIndex, newIndex }: SortEnd) => {
            const newItems = arrayMove(props.product.files, oldIndex, newIndex);
            const newFiles = newItems.map((item) => item.id);
            await axios.patch(`/products/${props.product.id}/files/reorder`, {
                fileIds: newFiles,
            });
            await props.refreshProduct();
            setImages(newItems);
            alert('Sira guncellendi!');
        },
        [props.product]
    );
    const onItemRemove = useCallback(
        async (fileId: string) => {
            try {
                await axios.delete(
                    `/products/${props.product.id}/files/${fileId}`
                );
                alert('Urun gorseli basariyla silindi!');
                props.refreshProduct();
            } catch (error) {
                console.error(
                    'Something went wrong during remove product file: ',
                    error
                );
                alert('Urun gorseli silinirken bir sorun olustu');
            }
        },
        [props.product.id]
    );

    const DragHandle = SortableHandle(() => (
        <span className={styles.dragHandle}>☰</span>
    ));

    const ProductImageSortableItem = useCallback(
        SortableElement<{ url: string; id: string }>(
            ({ url, id }: { url: string; id: string }) => (
                <li>
                    <DragHandle />
                    <img
                        src={`${imagePath}/public/product-images/${url}`}
                        alt="Product"
                        style={{ width: '150px', height: 'auto' }}
                    />
                    <span
                        className={styles.removePhoto}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onItemRemove(id);
                        }}
                    >
                        X
                    </span>
                </li>
            )
        ),
        [DragHandle]
    );
    const ProductImageSortableList = useCallback(
        SortableContainer<{
            items: { id: string; url: string }[];
        }>(({ items }: { items: Array<{ id: string; url: string }> }) => (
            <ul className={styles.photoList}>
                {items.map((image, index) => (
                    <ProductImageSortableItem
                        key={`item-${image.id}`}
                        index={index}
                        url={image.url}
                        id={image.id}
                    />
                ))}
            </ul>
        )),
        [DragHandle]
    );

    useEffect(() => {
        setImages(props.product.files);
    }, [props.product.files]);

    return (
        <div>
            <h2>Fotograf Yukle</h2>
            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: onSubmit,
                    },
                ]}
                onSubmit={onSubmit}
            >
                <FileInput
                    label="Dosya:"
                    onChange={setFile}
                    fileTypes={['image/png', 'image/jpeg']}
                />
            </Form>

            <h2>Fotograflar</h2>
            <ProductImageSortableList
                useDragHandle
                items={images
                    .sort((a, b) => a.order - b.order)
                    .map((item) => ({
                        id: item.file.id,
                        url: item.file.small,
                    }))}
                onSortEnd={onSortEnd}
                axis="y"
            />
        </div>
    );
}

export function ProductDetailPage() {
    const { productId } = useParams();
    const [product, setProduct] = useState<ProductDetail>({
        id: '',
        name: '',
        description: '',
        badge: '',
        state: ProductStates.ACTIVE,
        price: 0,
        abroadPrice: 0,
        category: {
            id: '',
            name: '',
            slug: '',
            state: CategoryStates.ACTIVE,
        },
        files: [],
        sizes: [],
    });
    const fetchProduct = useCallback(async () => {
        const res = await axios.get(`/products/${productId}`);
        setProduct(res.data);
    }, [axios, productId]);

    useEffect(() => {
        if (productId?.length) {
            fetchProduct();
        }
    }, [productId]);

    return (
        <div>
            <Tabs
                headers={['Urun Bilgileri', 'Bedenler', 'Resimler']}
                contents={[
                    <ProductInformations
                        refreshProduct={fetchProduct}
                        product={product}
                    />,
                    <ProductSizes
                        refreshProduct={fetchProduct}
                        product={product}
                    />,
                    <ProductImages
                        refreshProduct={fetchProduct}
                        product={product}
                    />,
                ]}
            />
        </div>
    );
}
