import { ProductSummary } from './product.type';
import { ProductSizeStock } from './productSizeStock.type';

export enum ProductSizeStates {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface CreateProductSizeRequest {
    name: string;
    productId: string;
    state: ProductSizeStates;
}

export interface UpdateProductSizeRequest {
    name?: string;
    state?: ProductSizeStates;
}

export interface ProductSize {
    id: string;
    name: string;
    productId: string;
    product: ProductSummary;
    state: ProductSizeStates;
    stocks: ProductSizeStock[];
    createdAt: Date;
    updatedAt: Date;
}
