import './App.css';

import {
    createBrowserRouter,
    Navigate,
    Outlet,
    RouterProvider,
} from 'react-router-dom';

import { Layout } from './components/Layout/Layout';
import { UserProvider } from './context/user.context';
import { Home } from './pages/Home/Home';
import { Login } from './pages/Login/Login';
import { ProductList } from './pages/ProductList/ProductList';
import { CreateProduct } from './pages/CreateProduct/CreateProduct';
import { CreateCategory } from './pages/CreateCategory/CreateCategory';
import { CategoryList } from './pages/CategoryList/CategoryList';
import { CategoryDetail } from './pages/CategoryDetail/CategoryDetail';
import { ProductDetailPage } from './pages/ProductDetail/ProductDetail';
import { SiteConfigDetail } from './pages/SiteConfigDetail/SiteConfigDetail';
import { PageOrderList } from './pages/OrderList/OrderList';
import { OrderDetail } from './pages/OrderDetail/OrderDetail';
import { SendEmail } from './pages/SendEmail/SendEmail';
import { MailingList } from './pages/MailingList/MailingList';
import { ShipmentOrderList } from './pages/ShipmentOrderList/ShipmentOrderList';

const UserProviderLayout = () => (
    <UserProvider>
        <Outlet />
    </UserProvider>
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <UserProviderLayout />,
        children: [
            {
                path: '/',
                element: <Layout />,
                children: [
                    {
                        path: '/',
                        element: <Home />,
                    },
                    {
                        path: '/products',
                        element: <Navigate to="/products/list" replace />,
                    },
                    {
                        path: '/products/list',
                        element: <ProductList />,
                    },
                    {
                        path: '/products/create',
                        element: <CreateProduct />,
                    },
                    {
                        path: '/products/:productId/detail',
                        element: <ProductDetailPage />,
                    },
                    {
                        path: '/categories',
                        element: <Navigate to="/categories/list" replace />,
                    },
                    {
                        path: '/categories/list',
                        element: <CategoryList />,
                    },
                    {
                        path: '/categories/create',
                        element: <CreateCategory />,
                    },
                    {
                        path: '/categories/:categoryId/detail',
                        element: <CategoryDetail />,
                    },
                    {
                        path: '/site-config',
                        element: <SiteConfigDetail />,
                    },
                    {
                        path: '/orders',
                        element: <PageOrderList />,
                    },
                    {
                        path: '/orders/waiting-for-shipment',
                        element: <ShipmentOrderList />,
                    },
                    {
                        path: '/orders/:orderId/detail',
                        element: <OrderDetail />,
                    },
                    {
                        path: '/mailings',
                        element: <MailingList />,
                    },
                    {
                        path: '/email/send',
                        element: <SendEmail />,
                    },
                ],
            },
            {
                path: '/login',
                element: <Login />,
            },
        ],
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
