export enum CategoryStates {
    ACTIVE = 'ACTIVE',
    DEACTIVE = 'DEACTIVE',
}

export interface CreateCategoryRequest {
    name: string;
    slug: string;
}

export interface UpdateCategoryRequest {
    name?: string;
    slug?: string;
    state?: CategoryStates;
}

export interface Category {
    id: string;
    name: string;
    slug: string;
    state: CategoryStates;
}
