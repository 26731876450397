import styles from './Table.module.css';

import React, { useCallback } from 'react';

import { PaginationParams } from '../../types/pagination.type';
import { SelectInput } from '../Input/Input';

export interface TableProps<T> {
    items: Array<T>;
    renderItems: (item: T, index: number) => React.ReactNode;
    renderHeaders: () => React.ReactNode;
    onPaginationChange?: (params: PaginationParams) => void;
    pagination?: PaginationParams;
    totalPages?: number;
}

export function Table<T>(props: TableProps<T>) {
    const handlePrevious = useCallback(() => {
        if (
            !props.pagination ||
            !props.onPaginationChange ||
            !props.totalPages
        ) {
            return;
        }

        props.onPaginationChange({
            ...props.pagination,
            page: props.pagination?.page - 1,
        });
    }, [props.pagination]);
    const handleNext = useCallback(() => {
        if (
            !props.pagination ||
            !props.onPaginationChange ||
            !props.totalPages
        ) {
            return;
        }
        props.onPaginationChange({
            ...props.pagination,
            page: props.pagination?.page + 1,
        });
    }, [props.pagination]);

    if (props.items.length === 0) {
        return <div>Kayit bulunamadi!</div>;
    }

    return (
        <>
            <table className={styles.table}>
                {props.renderHeaders()}
                <tbody>
                    {props.items.map((item, index) =>
                        props.renderItems(item, index)
                    )}
                </tbody>
            </table>

            {props.pagination &&
                props.onPaginationChange &&
                props.totalPages && (
                    <div className={styles.pagination}>
                        <button
                            onClick={handlePrevious}
                            disabled={props.pagination.page === 1}
                        >
                            Önceki Sayfa
                        </button>
                        <span>
                            Toplam: {props.totalPages} sayfadan{' '}
                            {props.pagination.page}. <br />
                            Sayfada toplam gosterilecek
                            <SelectInput
                                label=""
                                value={props.pagination.limit.toString()}
                                items={[
                                    {
                                        label: '10',
                                        val: '10',
                                    },
                                    {
                                        label: '20',
                                        val: '20',
                                    },
                                    {
                                        label: '30',
                                        val: '30',
                                    },
                                    {
                                        label: '40',
                                        val: '40',
                                    },
                                    {
                                        label: '50',
                                        val: '50',
                                    },
                                    {
                                        label: '100',
                                        val: '100',
                                    },
                                ]}
                                onChange={(val) => {
                                    props.onPaginationChange &&
                                        props.onPaginationChange({
                                            page: props.pagination?.page || 1,
                                            ...(props.pagination || {}),
                                            limit: Number(val),
                                        });
                                }}
                            />
                        </span>
                        <button
                            onClick={handleNext}
                            disabled={props.pagination.page >= props.totalPages}
                        >
                            Sonraki Sayfa
                        </button>
                    </div>
                )}
        </>
    );
}
