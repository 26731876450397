import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../components/Form/Form';
import { SelectInput, TextInput } from '../../components/Input/Input';
import {
    Category,
    CategoryStates,
    CreateCategoryRequest,
    UpdateCategoryRequest,
} from '../../types/category.type';
import { isSlug } from '../../utils/slug';

import styles from './CategoryDetail.module.css';

export function CategoryDetail() {
    const { categoryId } = useParams();
    const navigate = useNavigate();
    const [category, setCategory] = useState<Category>({
        id: '',
        name: '',
        slug: '',
        state: CategoryStates.DEACTIVE,
    });
    const [payload, setPayload] = useState<UpdateCategoryRequest>({});
    const onFieldChange = useCallback(
        function <K extends keyof UpdateCategoryRequest>(
            key: K,
            val: UpdateCategoryRequest[K]
        ) {
            setPayload({
                ...payload,
                [key]: val,
            });
        },
        [payload]
    );
    const fetchCategory = useCallback(async () => {
        const res = await axios(`/categories/${categoryId}`);
        setCategory(res.data);
    }, [axios, categoryId]);

    const onSaveCategory = useCallback(async () => {
        if (payload.slug && !isSlug(payload.slug)) {
            alert('Kategori linki sadece a-z ve - icerebilir!');
            return;
        }

        try {
            await axios.patch(`/categories/${categoryId}`, {
                ...payload,
            });

            alert('Kategori basariyla guncellendi!');
            await fetchCategory();
        } catch (error) {
            alert('Kategori guncellenirken bir sorun olustu!');
            return;
        }
    }, [axios, payload, categoryId]);

    useEffect(() => {
        if (categoryId?.length) {
            fetchCategory();
        }
    }, [axios, categoryId]);

    return (
        <div className={styles.container}>
            <h2>Kategori Detayi</h2>

            <Form
                actions={[
                    {
                        text: 'Kaydet',
                        onClick: onSaveCategory,
                    },
                ]}
                onSubmit={onSaveCategory}
            >
                <TextInput
                    label="Isim:"
                    defaultValue={category.name}
                    onChange={(val) => onFieldChange('name', val)}
                />
                <TextInput
                    label="Link:"
                    defaultValue={category.slug}
                    onChange={(val) => onFieldChange('slug', val)}
                />
                <SelectInput
                    label="Kategori:"
                    value={payload.state || category.state}
                    onChange={(val) => onFieldChange('state', val)}
                    items={[
                        {
                            val: CategoryStates.ACTIVE,
                            label: 'Aktif',
                        },
                        {
                            val: CategoryStates.DEACTIVE,
                            label: 'Deaktif',
                        },
                    ]}
                />
            </Form>
        </div>
    );
}
